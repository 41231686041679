import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Layout,
  Modal,
  Select,
} from 'antd';
import HeaderComponent from '../Header/Header';
import './rightPane.scss';
import dayjs from 'dayjs';
import { dateTimeFormats } from '../../settings/settings';
import { Outlet } from 'react-router-dom';

interface RightPaneProps {}

const { Content } = Layout;

export default function ({}: RightPaneProps) {
  const [open, setOpen] = useState(false);
  return (
    <Layout className="h-screen">
      <HeaderComponent />
      <Content
        className="site-layout-background m-4"
        style={{ padding: 24, minHeight: 360 }}
      >
        <Outlet />
      </Content>
    </Layout>
  );
}
