import React, { useEffect, useRef, useState } from 'react';
import {
  FeatureGroup,
  ImageOverlay,
  MapContainer,
  Polygon,
} from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import L, { LatLng } from 'leaflet';
import useWindowSize from '../../../../hooks/useWindowSize';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
//import './mapView.scss';

interface MapViewProps {
  imageUrl: string;
  bbox: Array<[number, number]>;
  setPolygon: (x: Array<[number, number]>) => void;
  isDrawing: boolean;
  setIsDrawing: (x: boolean) => void;
  isEditing: boolean;
  setIsEditing: (x: boolean) => void;
  isDeleting: boolean;
  setIsDeleting: (x: boolean) => void;
}

const MapView = ({
  imageUrl,
  bbox,
  setPolygon,
  isDrawing,
  setIsDrawing,
  isEditing,
  setIsEditing,
  isDeleting,
  setIsDeleting,
}: MapViewProps) => {
  const [mapHeight, setMapHeight] = useState(500);
  const [width, height] = useWindowSize();
  const [imgWidth, setImgWidth] = useState(0);
  const [imgHeight, setImgHeight] = useState(0);
  const mapRef = useRef<any>(null);
  const [bboxVertices, setBboxVertices] = useState<Array<[number, number]>>([]);

  useEffect(() => {
    setMapHeight(height - 150);
  }, [height]);

  useEffect(() => {
    const vertices: Array<[number, number]> = bbox.map(
      (vertex: [number, number]) => [vertex[1], vertex[0]]
    );
    setBboxVertices(vertices);
  }, [bbox]);

  useEffect(() => {
    var img = new Image();
    img.onload = function () {
      setImgWidth(img.width);
      setImgHeight(img.height);
    };
    img.src = imageUrl;
  }, [imageUrl]);

  useEffect(() => {
    mapRef?.current?.fitBounds([
      [0, 0],
      [imgHeight, imgWidth],
    ]);
  }, [imgHeight, imgWidth]);

  const handleCreate = (e: any) => {
    //console.log(e, 'create polygon');

    const { layerType, layer } = e;

    if (layerType === 'polygon') {
      setIsDrawing(false);
      const latlngs = layer.getLatLngs()[0];
      const coordinates = latlngs.map(
        (latlng: { lat: number; lng: number }) => [latlng.lng, latlng.lat]
      );
      setPolygon(coordinates);
    }
  };

  const handleEdit = (e: any) => {
    //console.log(e, 'edit polygon');

    const { layers } = e;
    const { _layers } = layers;

    const editedLayerIds = Object.keys(_layers);

    if (editedLayerIds.length) {
      setIsEditing(false);
      const updtLayerId = editedLayerIds[0];
      const updtLatLngs = _layers[updtLayerId]._latlngs[0];
      const updtCoords = updtLatLngs.map(
        (latlng: { lat: number; lng: number }) => [latlng.lng, latlng.lat]
      );
      setPolygon(updtCoords);
    }
  };
  const handleDelete = (e: any) => {
    //console.log(e, 'delete polygon');

    const { layers } = e;
    const { _layers } = layers;

    const deletedLayerIds = Object.keys(_layers);

    if (deletedLayerIds.length) {
      setIsDeleting(false);
      setPolygon([]);
    }
  };

  return (
    <div style={{ height: mapHeight }}>
      <MapContainer
        ref={mapRef}
        crs={L.CRS.Simple}
        style={{ height: '100%' }}
        minZoom={-2}
      >
        <ImageOverlay
          url={imageUrl}
          bounds={[
            [0, 0],
            [imgHeight, imgWidth],
          ]}
        />
        <FeatureGroup>
          <EditControl
            position="topright"
            onCreated={handleCreate}
            onEdited={handleEdit}
            onDeleted={handleDelete}
            draw={{
              rectangle: false,
              polyline: false,
              circle: false,
              circlemarker: false,
              marker: false,
              polygon: isDrawing,
            }}
            edit={{
              edit: !isEditing ? false : {},
              remove: isDeleting,
            }}
          />

          {bboxVertices.length > 0 && <Polygon positions={bboxVertices} />}
        </FeatureGroup>
      </MapContainer>
    </div>
  );
};

export default MapView;
