import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Col,
  Drawer,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
  Switch,
  TreeSelect,
} from 'antd';
import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
//import SignaturePad from 'react-signature-pad-wrapper';
import { Columns } from './Columns';
import MaximlTable from '../../components/MaximlTable/Table';
import {
  BASE_URL,
  CLOUDINARY_API_KEY,
  CLOUDINARY_UPLOAD_PRESET,
  CLOUDINARY_URL,
} from '../../env';
import { Http2ServerRequest } from 'http2';
import axios from 'axios';

type ProfileProps = {
  visible: boolean;
  setVisible: (val: boolean) => void;
  data: any;
  //roles: Array<any>;
  showSign: boolean;
};

const Profile = (props: ProfileProps) => {
  const { visible, setVisible, data, showSign } = props;
  const [form] = Form.useForm();
  const [groups, setGroups] = useState<any>([]);
  const [isEditable, setIsEditable] = useState<any>(false);

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);

  const signCanvas = useRef() as React.MutableRefObject<any>;
  const save = async () => {
    const file_data = signCanvas.current.toDataURL('image/png');
    // console.log(signCanvas.current.toDataURL('image/png'))
    const data = new FormData();

    data.append('file', file_data);

    data.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
    data.append('api_key', CLOUDINARY_API_KEY);

    data.append('cloud_name', 'signature');

    return await axios.post(`${CLOUDINARY_URL}${'image'}/upload`, data);
  };

  const onFinish = async (FormData: any) => {
    setVisible(false);
    const response = await save();
    console.log(response.data.secure_url);

    FormData = {
      ...FormData,
      signature: response.data.secure_url,
      skills: data.skills,
    };
    let url = BASE_URL + `/users/` + data.id;

    try {
      message.loading({
        content: 'Updating profile data...',
        duration: 0,
        key: 'loading_msg',
      });
      await axios.put(url, FormData);
      message.destroy('loading_msg');
      message.success(`Profile updated successfully.`);
    } catch (error: any) {
      console.log(error);
      message.destroy('loading_msg');
      message.error(error.message);
    }
  };
  const getGroupsData = async () => {
    let url = BASE_URL + `/groups`;

    try {
      await axios
        .request({
          method: 'get',
          url,
        })
        .then((response: any) => {
          //console.log(response.data);
          setGroups(response.data);
        });
    } catch (error) {
      //console.log(error);
    }
  };
  useEffect(() => {
    getGroupsData();
  }, []);

  return (
    <Drawer
      title="Profile"
      placement="right"
      onClose={() => setVisible(false)}
      visible={visible}
      width={'700'}
    >
      <div className="mb-16">
        <div className="flex items-center mb-5">
          <Avatar
            size={80}
            style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
          >
            {data.username?.substring(0, 1) +
              data.username?.substring(
                data.username?.lastIndexOf(' ') + 1,
                data.username?.lastIndexOf(' ') + 2
              )}
          </Avatar>
          <div className="flex flex-col ml-4">
            <span className="text-lg font-semibold">{data.username}</span>
            <span className="text-sm">{data.designation}</span>
          </div>
        </div>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          initialValues={data}
        >
          <div className="flex w-full mt-2">
            <Form.Item
              label="Full Name:"
              name="username"
              className="w-full mr-2"
            >
              <Input readOnly={true} placeholder="Please enter full name" />
            </Form.Item>
            <Form.Item label="Email:" name="email" className="w-full mr-2">
              <Input readOnly={true} placeholder="Please enter email" />
            </Form.Item>
          </div>
          <div className="flex w-full mt-2">
            <Form.Item label="Role:" name="role" className="w-full">
              <Input readOnly={true} placeholder="Please enter role" />
            </Form.Item>
            <Form.Item label="Phone Number:" className="w-full ml-2">
              <Input.Group>
                <Row gutter={8}>
                  <Col span={6}>
                    <Input defaultValue="+91" />
                  </Col>
                  <Col span={18}>
                    <Form.Item name="phone_no" noStyle>
                      <Input placeholder="Please enter phone number" />
                    </Form.Item>
                  </Col>
                </Row>
              </Input.Group>
            </Form.Item>
          </div>
          <div className="flex w-full mt-2">
            <Form.Item label="Groups" name="groups" className="w-full mr-2">
              <Select placeholder="Please select group" open={false}>
                {groups.map((item: any) => (
                  <Select.Option key={item.name}>
                    {item.team_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Area" name="area" className="w-full">
              <Input readOnly={true} placeholder="Please enter area" />
            </Form.Item>
          </div>
          <div className="flex w-full">
            <div className="flex w-[50%] mr-2">
              <Form.Item label="Designation" name="" className="w-1/3 mr-2">
                <Select placeholder="Prefix" open={false}>
                  <Select.Option>Trainee</Select.Option>
                  <Select.Option>Junior</Select.Option>
                  <Select.Option>Associate</Select.Option>
                  <Select.Option>Senior</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label=" " name="name" className="w-2/3">
                <Input readOnly={true} placeholder="Your Designation.." />
              </Form.Item>
            </div>
            <div className="flex w-[50%]"></div>
          </div>
          <div style={{ border: '1px solid #e0e0e0' }}>
            <MaximlTable
              data={data.skills || []}
              columns={Columns.Skills}
              loading={false}
              // editableCell
              // onRightClick={()=>{setIsEditable(true)}}
            />
          </div>
          <div className="my-2">
            <Form.Item label="Active" name="is_active">
              <Switch defaultChecked={data.is_active}></Switch>
            </Form.Item>
          </div>

          {/* <Form.Item label="Signature:" name="signature">
            <div
              className="w-full rounded-sm"
              style={{ border: '1px solid #e0e0e0' }}
            >
              {showSign ? (
                <SignaturePad ref={signCanvas} />
              ) : (
                <img
                  className="object-contain w-full h-full"
                  src={data.signature}
                ></img>
              )}
            </div>
          </Form.Item> */}

          <Form.Item className=" my-2">
            <Button
              type="primary"
              htmlType="submit"
              className={showSign ? 'visible' : 'hidden'}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
        {data.id === null && (
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              console.log(form.getFieldsValue());
            }}
          >
            Submit
          </Button>
        )}
      </div>
    </Drawer>
  );
};

export default Profile;
