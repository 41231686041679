import { Switch } from 'antd';
import React from 'react';

export const ToggleColumnFilter = ({ column }: any) => {
  const { filterValue, setFilter } = column;

  const handleReverseChange = (toggle: any) => {
    setFilter(toggle);
  };

  return (
    <div className="an-flex an-justify-start an-items-center an-mt-3">
      <Switch
        size="small"
        defaultChecked={false}
        // checked={filterValue}
        onChange={handleReverseChange}
      />
    </div>
  );
};
