import React, { useEffect, useState } from 'react';
import { Layout, Menu, MenuProps } from 'antd';
import {
  HomeOutlined,
  UserOutlined,
  VideoCameraOutlined,
  VideoCameraAddOutlined,
  CameraOutlined,
  HddOutlined,
  ControlOutlined,
} from '@ant-design/icons';
import './leftPane.scss';
import useMatchedRoute from '../../hooks/useMatchedRoute';
import { useNavigate } from 'react-router-dom';

const { Sider } = Layout;

interface LeftPaneProps {}

const LeftPane = (props: LeftPaneProps) => {
  const navigate = useNavigate();
  const route = useMatchedRoute();
  const [openKeys, setOpenKeys] = useState<any>([]);
  const [collapsed, setCollapsed] = useState(false);
  type MenuItem = Required<MenuProps>['items'][number];
  const getItem = (
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[]
  ): MenuItem => {
    return {
      key,
      icon,
      children,
      label,
    } as MenuItem;
  };

  const items: MenuItem[] = [
    // getItem('Home', '/home', <HomeOutlined />),
    // getItem('Live Feed', '/live_feed', <VideoCameraAddOutlined />),
    getItem(
      'Safety Observations',
      '/safety_observations',
      <VideoCameraOutlined />
    ),
    getItem('Configuration', '/configuration', <HddOutlined />, [
      getItem('Cameras', '/cameras', <CameraOutlined />),
      getItem('Edge Devices', '/edge_devices', <ControlOutlined />),
      getItem('Users', '/users', <UserOutlined />),
    ]),
  ];

  useEffect(() => {
    console.log(route, 'current route');
    if (route?.parent_key !== undefined) {
      setOpenKeys([route.parent_key.toString()]);
    }
  }, [route]);

  const onOpenChange = (_openKeys: any) => {
    setOpenKeys([..._openKeys]);
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      className="h-screen overflow-y-auto"
    >
      <div className="h-14 flex items-center justify-center">
        <img
          src={
            collapsed
              ? 'https://res.cloudinary.com/dzsifr04l/image/upload/v1667547471/gallery/maximl_logo_white_72ppi_tgr5t8.png'
              : 'https://res.cloudinary.com/dzsifr04l/image/upload/v1667546876/gallery/maximl_logo_with_text_white_36ppi_lghcvs.png'
          }
          className="object-contain w-4/5 h-6"
          alt="Maximl Logo"
        />
      </div>
      <Menu
        selectedKeys={
          route!.path === '/' ? ['/safety_observations'] : [route!.path]
        }
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        theme="dark"
        mode="inline"
        items={items}
        onClick={(obj) => {
          navigate(obj.key);
        }}
      />
    </Sider>
  );
};

export default LeftPane;
