import React, { useEffect, useState } from 'react';
import MaximlTable from '../../../../components/MaximlTable/Table';
import { IFeature, IRoi } from '../../../../interfaces/roi.interface';
import { DeleteTwoTone, EditTwoTone, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Divider,
  Image,
  Input,
  InputNumber,
  Modal,
  message,
} from 'antd';
import MapView from '../MapView/MapView';
import { ICamera } from '../../../../interfaces/camera.interface';
import { BASE_URL } from '../../../../env';
import axios from 'axios';
interface roiPropsType {
  cameraDetails: ICamera;
  activeTab: string;
}

const ROIs = ({ cameraDetails, activeTab }: roiPropsType) => {
  const [open, setOpen] = useState(false);
  const [isDrawing, setIsDrawing] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [loader, setLoader] = useState(true);
  const [rois, setRois] = useState<Array<IRoi>>([]);
  const initialRoi = {
    code: '',
    bbox: [],
    features: [
      {
        title: 'Intrusion Detection',
        is_enabled: false,
        config: {
          'Min Time': 0,
        },
      },
      {
        title: 'Missing PPE Detection',
        is_enabled: false,
        config: {
          'Min Time': 0,
          Helmet: false,
          'Safety Vest': false,
        },
      },
      {
        title: 'Fall Detection',
        is_enabled: false,
        config: {
          'Min person count': 0,
          'Min Time': 0,
        },
      },
    ],
  };
  const [roi, setRoi] = useState<IRoi>(initialRoi);
  const featureSet = [
    'Intrusion Detection',
    'Missing PPE Detection',
    'Fall Detection',
  ];
  const [polygon, setPolygon] = useState<Array<[number, number]>>([]);

  const getRois = async () => {
    let url = BASE_URL + `/cameras/${cameraDetails.id}/roi`;

    await axios
      .get(url)
      .then((res) => {
        setRois(res.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        message.error(err.response?.data?.message);
      });
  };

  useEffect(() => {
    if (activeTab === '2') {
      getRois();
    }
  }, [cameraDetails, activeTab]);

  const startDrawing = () => {
    setIsDrawing(true);
  };

  const editDrawing = () => {
    setIsEditing(true);
  };

  const deleteDrawing = () => {
    setIsDeleting(true);
  };

  const deleteRoi = async (roiId: string) => {
    let url = BASE_URL + `/cameras/${cameraDetails.id}/roi/${roiId}`;

    message.loading({
      content: 'Deleting ROI...',
      duration: 0,
      key: 'deleting_msg',
    });

    await axios
      .delete(url)
      .then((res) => {
        message.destroy('deleting_msg');
        const tmpRois = rois.filter((r: IRoi) => r.id !== roiId);
        setRois(tmpRois);
      })
      .catch((err) => {
        message.destroy('deleting_msg');
        message.error(err.response?.data?.message);
      });
  };

  const Columns = [
    {
      Header: 'ROI Id',
      Footer: 'ROI Id',
      accessor: 'code',
      maxWidth: 500,
      minWidth: 150,
      width: 100,
      Cell: (cell: any) => {
        return (
          <div
            className="text-[15px] font-semibold ml-2 text-blue-500 underline underline-offset-1"
            onClick={() => {
              setOpen(true);
              setRoi(cell.row.original);
              editDrawing();
            }}
          >
            {cell.value}
          </div>
        );
      },
    },
    {
      Header: 'Thumbnail',
      Footer: 'Thumbnail',
      accessor: 'thumbnail',
      maxWidth: 500,
      minWidth: 150,
      width: 100,
      Cell: (cell: any) => {
        const url = cameraDetails.thumbnail;
        const getThumbnailUrl = () => {
          const thumbnailParams = '/c_thumb,q_70,h_70,w_80/';
          const versionIndex = url.indexOf('/v');
          const modifiedUrl =
            url.slice(0, versionIndex) +
            thumbnailParams +
            url.slice(versionIndex + 1);
          return modifiedUrl;
        };
        if (url) {
          return <Image src={getThumbnailUrl()} preview={{ src: url }} />;
        }
      },
    },
    {
      Header: 'Features enabled',
      Footer: 'Features enabled',
      accessor: 'features',
      maxWidth: 350,
      minWidth: 150,
      width: 250,
      Cell: (cell: any) => {
        return (
          <div>
            {cell.value?.map(
              (feature: IFeature) =>
                feature.is_enabled && (
                  <p className="text-[14px] font-medium">SO: {feature.title}</p>
                )
            )}
          </div>
        );
      },
    },
    {
      Header: '',
      Footer: '',
      accessor: 'id',
      maxWidth: 350,
      minWidth: 150,
      width: 100,
      Cell: (cell: any) => {
        return (
          <div className="flex justify-between mx-5">
            <EditTwoTone
              style={{ fontSize: '20px' }}
              onClick={() => {
                setOpen(true);
                setRoi(cell.row.original);
                editDrawing();
              }}
            />
            <DeleteTwoTone
              style={{ fontSize: '18px' }}
              onClick={() => {
                deleteRoi(cell.row.original.id);
              }}
            />
          </div>
        );
      },
    },
  ];

  const customButton = () => {
    return (
      <div className="ml-auto">
        <Button
          type="primary"
          onClick={() => {
            setOpen(true);
            setRoi(initialRoi);
            startDrawing();
          }}
          className="ml-2"
        >
          <div className="flex items-center justify-center">
            <PlusOutlined /> <span className="ml-1">Add ROI</span>
          </div>
        </Button>
      </div>
    );
  };

  const resetDrawingStates = () => {
    setIsDrawing(false);
    setIsEditing(false);
    setIsDeleting(false);
  };

  const editRoi = async () => {
    let url = BASE_URL + `/cameras/${cameraDetails.id}/roi/${roi.id}`;

    let vals = {
      code: roi.code,
      bbox: polygon,
      roi_features: roi.features,
    };
    message.loading({
      content: 'Updating ROI...',
      duration: 0,
      key: 'loading_msg',
    });
    await axios
      .patch(url, vals)
      .then((res) => {
        message.destroy('loading_msg');
        let tmpData = rois.filter((r: IRoi) => r.id !== roi.id);
        setRois([res.data, ...tmpData]);
      })
      .catch((err) => {
        message.destroy('loading_msg');
        message.error(err.response?.data?.message);
      });
  };

  const addRoi = async () => {
    let url = BASE_URL + `/cameras/${cameraDetails.id}/roi`;

    let vals = {
      code: roi.code,
      bbox: polygon,
      roi_features: roi.features,
    };
    message.loading({
      content: 'Adding ROI...',
      duration: 0,
      key: 'loading_msg',
    });
    await axios
      .post(url, vals)
      .then((res) => {
        message.destroy('loading_msg');
        setRois((prevState) => [res.data, ...prevState]);
      })
      .catch((err) => {
        message.destroy('loading_msg');
        message.error(err.response?.data?.message);
      });
  };

  function updateRoiFeatureConfig(
    featId: number,
    configName: string,
    configVal: any
  ) {
    setRoi((prevState) => {
      const updtFts = prevState.features.map((f: IFeature) => {
        if (f.title === featureSet[featId]) {
          return {
            ...f,
            config: { ...f.config, [configName]: configVal },
          };
        } else {
          return f;
        }
      });

      return { ...prevState, features: updtFts };
    });
  }

  function updateRoiFeatureSelection(featId: number, isEnabled: boolean) {
    setRoi((prevState) => {
      const updtFts = prevState.features.map((f: IFeature) => {
        if (f.title === featureSet[featId]) {
          return {
            ...f,
            is_enabled: isEnabled,
          };
        } else {
          return f;
        }
      });

      return { ...prevState, features: updtFts };
    });
  }

  return (
    <>
      <div className="mx-2">
        <MaximlTable
          data={rois}
          columns={Columns}
          loading={loader}
          customButton={customButton}
        />
      </div>
      <Modal
        title={roi.id ? 'Edit ROI' : 'Add ROI'}
        open={open}
        centered
        onOk={() => {
          if (roi.code.length == 0) {
            message.error('ROI code should not be empty!');
            return;
          }
          setOpen(false);
          if (roi.id) {
            editRoi();
          } else {
            addRoi();
          }
          resetDrawingStates();
        }}
        okText={roi.id ? 'Save' : 'Add'}
        cancelButtonProps={{ hidden: true }}
        onCancel={() => {
          setOpen(false);
          resetDrawingStates();
        }}
        bodyStyle={{ height: 600 }}
        width={1300}
        destroyOnClose
      >
        <div className="flex justify-between w-full">
          <div className="flex-col w-[50%] mr-6">
            <div className="flex items-center">
              <span className="text-[16px] font-semibold m-4">ID : </span>
              <Input
                placeholder="Enter ROI code.."
                defaultValue={roi?.code}
                style={{ width: '20%' }}
                onBlur={(e) => {
                  setRoi((prevState) => ({
                    ...prevState,
                    code: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="w-full mx-2">
              <MapView
                imageUrl={cameraDetails.thumbnail}
                bbox={roi.bbox}
                setPolygon={setPolygon}
                isDrawing={isDrawing}
                setIsDrawing={setIsDrawing}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                isDeleting={isDeleting}
                setIsDeleting={setIsDeleting}
              />
            </div>
          </div>
          <div className="flex-col w-[50%] mt-6">
            <p className="text-[16px] font-semibold">Features :</p>
            <div className="p-2 mb-4 border border-gray-300 border-solid">
              <div className="flex px-2 mb-0">
                <span className="text-[14px] font-semibold mr-2">
                  Safety Observation :{' '}
                </span>
                <span className="text-[14px] font-semibold">
                  {featureSet[0]}
                </span>
                <span className="ml-auto">
                  <Checkbox
                    defaultChecked={
                      roi.features.find(
                        (f: IFeature) => f.title === featureSet[0]
                      )?.is_enabled
                    }
                    onChange={(e) => {
                      updateRoiFeatureSelection(0, e.target.checked);
                    }}
                  />
                </span>
              </div>
              <Divider style={{ backgroundColor: '#E0E0E0' }} />
              <div className="flex items-center px-2">
                <span className="text-[14px] font-semibold mr-2">
                  Min Time (in seconds) :{' '}
                </span>
                <InputNumber
                  style={{ width: '15%' }}
                  min={0}
                  defaultValue={
                    roi?.features?.find(
                      (f: IFeature) => f.title === featureSet[0]
                    )?.config['Min Time']
                  }
                  onBlur={(e) => {
                    updateRoiFeatureConfig(
                      0,
                      'Min Time',
                      parseInt(e.target.value)
                    );
                  }}
                />
              </div>
            </div>
            <div className="p-2 mb-4 border border-gray-300 border-solid">
              <div className="flex px-2 mb-0">
                <span className="text-[14px] font-semibold mr-2">
                  Safety Observation :{' '}
                </span>
                <span className="text-[14px] font-semibold">
                  {featureSet[1]}
                </span>
                <span className="ml-auto">
                  <Checkbox
                    defaultChecked={
                      roi.features.find(
                        (f: IFeature) => f.title === featureSet[1]
                      )?.is_enabled
                    }
                    onChange={(e) => {
                      updateRoiFeatureSelection(1, e.target.checked);
                    }}
                  />
                </span>
              </div>
              <Divider style={{ backgroundColor: '#E0E0E0' }} />
              <div className="flex items-center px-2">
                <span className="text-[14px] font-semibold mr-2">
                  Min Time (in seconds) :{' '}
                </span>
                <InputNumber
                  style={{ width: '15%' }}
                  min={0}
                  defaultValue={
                    roi?.features?.find(
                      (f: IFeature) => f.title === featureSet[1]
                    )?.config['Min Time']
                  }
                  onBlur={(e) => {
                    updateRoiFeatureConfig(
                      1,
                      'Min Time',
                      parseInt(e.target.value)
                    );
                  }}
                />
              </div>
              <div className="flex items-center px-2">
                <span className="text-[14px] font-semibold mr-6">Helmet :</span>
                <Checkbox
                  defaultChecked={
                    roi?.features?.find(
                      (f: IFeature) => f.title === featureSet[1]
                    )?.config['Helmet']
                  }
                  onChange={(e) => {
                    updateRoiFeatureConfig(1, 'Helmet', e.target.checked);
                  }}
                />
              </div>
              <div className="flex items-center px-2">
                <span className="text-[14px] font-semibold mr-2">
                  Safety Vest :
                </span>
                <Checkbox
                  defaultChecked={
                    roi?.features?.find(
                      (f: IFeature) => f.title === featureSet[1]
                    )?.config['Safety Vest'] ?? false
                  }
                  onChange={(e) => {
                    updateRoiFeatureConfig(1, 'Safety Vest', e.target.checked);
                  }}
                />
              </div>
            </div>
            <div className="p-2 mb-4 border border-gray-300 border-solid">
              <div className="flex px-2 mb-0">
                <span className="text-[14px] font-semibold mr-2">
                  Safety Observation :{' '}
                </span>
                <span className="text-[14px] font-semibold">
                  {featureSet[2]}
                </span>
                <span className="ml-auto">
                  <Checkbox
                    defaultChecked={
                      roi.features.find(
                        (f: IFeature) => f.title === featureSet[2]
                      )?.is_enabled
                    }
                    onChange={(e) => {
                      updateRoiFeatureSelection(2, e.target.checked);
                    }}
                  />
                </span>
              </div>
              <Divider style={{ backgroundColor: '#E0E0E0' }} />
              {/* <div className="flex items-center px-2 mb-2">
                <span className="text-[14px] font-semibold mr-2">
                  Min person count :{' '}
                </span>
                <InputNumber
                  style={{ width: '15%' }}
                  min={0}
                  defaultValue={
                    roi?.features?.find(
                      (f: IFeature) => f.title === featureSet[2]
                    )?.config['Min person count']
                  }
                  onBlur={(e) => {
                    updateRoiFeatureConfig(
                      2,
                      'Min person count',
                      parseInt(e.target.value)
                    );
                  }}
                />
              </div> */}
              <div className="flex items-center px-2">
                <span className="text-[14px] font-semibold mr-2">
                  Min Time (in seconds) :{' '}
                </span>
                <InputNumber
                  style={{ width: '15%' }}
                  min={0}
                  defaultValue={
                    roi?.features?.find(
                      (f: IFeature) => f.title === featureSet[2]
                    )?.config['Min Time']
                  }
                  onBlur={(e) => {
                    updateRoiFeatureConfig(
                      2,
                      'Min Time',
                      parseInt(e.target.value)
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ROIs;
