import 'regenerator-runtime/runtime';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Authentication from './pages/Authentication/Authentication';
import { useAppSelector } from './state/hooks';
import RightPane from './components/RightPane/RightPane';
import Home from './pages/Home/Home';
import Overview from './pages/Overview/Overview';
import EdgeDevices from './pages/EdgeDevices/EdgeDevices';
import Users from './pages/Users/Users';
import Cameras from './pages/Cameras/Cameras';
import LiveFeed from './pages/LiveFeed/LiveFeed';
import SafetyObservation from './pages/SafetyObservation/SafetyObservation';

const App = () => {
  const loggedIn = useAppSelector((state: any) => state.auth.loggedIn);
  const authChecker = loggedIn ? <Home /> : <Authentication />;
  return (
    <>
      <Routes>
        <Route path="/" element={authChecker}>
          {/* <Route path="/" element={<Overview />} /> */}
          <Route path="/" element={<SafetyObservation />} />
          <Route path="/home" element={<Overview />} />
          <Route path="/live_feed" element={<LiveFeed />} />
          <Route path="/safety_observations" element={<SafetyObservation />} />
          <Route path="/edge_devices" element={<EdgeDevices />} />
          <Route path="/cameras" element={<Cameras />} />
          <Route path="/users" element={<Users />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
