import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Dropdown,
  Empty,
  Form,
  Image,
  Input,
  Menu,
  Modal,
  Pagination,
  PaginationProps,
  Select,
  Spin,
  Tag,
  message,
} from 'antd';
import { MenuProps } from 'antd/es/menu';
import { DropdownColumnFilter } from '../../components/MaximlTable/Filters/DropdownColumnFilter';
import { StringColumnFilter } from '../../components/MaximlTable/Filters/StringColumnFilter';
import MaximlTable from '../../components/MaximlTable/Table';
import { dateTimeFormat } from '../../settings/settings';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetSafetyObservationsQuery } from '../../state/reducers/apiReducer/paginationSlice/paginationSlice';
import { DateColumnFilter } from '../../components/MaximlTable/Filters/DateColumnFilter';
import { EllipsisOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { BASE_URL } from '../../env';
import axios from 'axios';
import { IMetaUser, IUser } from '../../interfaces/user.interface';
import TextArea from 'antd/es/input/TextArea';
import VideoPlayer from '../Cameras/Components/VideoPlayer';

interface IOption {
  label: string;
  value: string;
}

const { confirm } = Modal;

const SafetyObservation = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);
  const [openedObservation, setOpenedObservation] = useState<any>();
  const [visible, setVisible] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isActionNeeded, setIsActionNeeded] = useState('No');
  const [form] = Form.useForm();
  const priorityOptions = [
    { label: 'High', value: 'High' },
    { label: 'Medium', value: 'Medium' },
    { label: 'Low', value: 'Low' },
  ];
  const statusOptions = [
    { label: 'Open', value: 'Open' },
    { label: 'Closed', value: 'Closed' },
  ];
  const tagOptions = [
    { label: 'Safety', value: 'Safety' },
    { label: 'Inspection', value: 'Inspection' },
    { label: 'Hazard', value: 'Hazard' },
    { label: 'Audit', value: 'Audit' },
  ];
  const [users, setUsers] = useState<Array<any>>([]);
  const [assigneeOptions, setAssigneeOptions] = useState<Array<IOption>>([]);

  const getUsersData = async () => {
    let url = BASE_URL + '/users';

    await axios
      .get(url)
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        console.log(err?.message, 'users error response');
      });
  };
  useEffect(() => {
    getUsersData();
  }, []);

  useEffect(() => {
    let tmpOpts: Array<IOption> = [];
    users.map((user: IUser) => {
      tmpOpts.push({
        label: user.username,
        value: user.id,
      });
    });
    setAssigneeOptions(tmpOpts);
  }, [users]);

  const {
    data: paginationLogs,
    error,
    isFetching,
    refetch,
  } = useGetSafetyObservationsQuery({ page, pageSize });

  useEffect(() => {
    //console.log('refetching');
    refetch();
  }, [page, pageSize]);

  useEffect(() => {
    const pageParam = searchParams.get('page');
    const limitParam = searchParams.get('limit');

    if (!pageParam && !limitParam) {
      setSearchParams({
        page: '1',
        limit: '5',
      });
      return;
    }
  }, [searchParams]);

  const itemRender: PaginationProps['itemRender'] = (
    _,
    type,
    originalElement
  ) => {
    if (type === 'prev') {
      return <a>Previous</a>;
    }
    if (type === 'next') {
      return <a>Next</a>;
    }
    return originalElement;
  };

  const Columns = [
    {
      Header: 'Title',
      Footer: 'Title',
      accessor: 'title',
      Filter: StringColumnFilter,
      canGroupBy: false,
      maxWidth: 350,
      minWidth: 150,
      width: 100,
    },
    {
      Header: 'Timestamp',
      Footer: 'Timestamp',
      accessor: 'time_of_observation',
      Filter: DateColumnFilter,
      canGroupBy: false,
      maxWidth: 500,
      minWidth: 150,
      width: 200,
      Cell: (cell: any) => {
        if (cell.row.isGrouped || cell.value === '') {
          return <span></span>;
        }
        return <span>{dayjs(cell.value).format(dateTimeFormat)}</span>;
      },
    },
    {
      Header: 'Thumbnail',
      Footer: 'Thumbnail',
      accessor: 'thumbnail',
      disableFilters: true,
      canGroupBy: false,
      maxWidth: 500,
      minWidth: 150,
      width: 100,
      Cell: (cell: any) => {
        if (cell.value) {
          return <Image src={cell.value} preview={cell.value} />;
        }
      },
    },
    {
      Header: 'Observation',
      Footer: 'Observation',
      accessor: 'code',
      maxWidth: 500,
      Filter: StringColumnFilter,
      canGroupBy: false,
      minWidth: 150,
      width: 100,
      Cell: (cell: any) => {
        return (
          <div
            className="font-semibold text-blue-500 underline text-[14px] ml-2"
            onClick={() => {
              setOpenedObservation(cell.row.original);
              setVisible(true);
            }}
          >
            {cell.value}
          </div>
        );
      },
    },
    {
      Header: 'Type',
      Footer: 'Type',
      accessor: 'observation_type',
      Filter: DropdownColumnFilter,
      maxWidth: 350,
      minWidth: 150,
      width: 100,
    },
    {
      Header: 'Area',
      Footer: 'Area',
      accessor: 'area',
      Filter: StringColumnFilter,
      canGroupBy: false,
      maxWidth: 350,
      minWidth: 150,
      width: 100,
    },
    {
      Header: 'Camera',
      Footer: 'Camera',
      accessor: 'camera_code',
      Filter: StringColumnFilter,
      canGroupBy: false,
      maxWidth: 350,
      minWidth: 150,
      width: 100,
    },
    {
      Header: 'ROI',
      Footer: 'ROI',
      accessor: 'roi_code',
      Filter: StringColumnFilter,
      canGroupBy: false,
      maxWidth: 350,
      minWidth: 150,
      width: 100,
    },
    {
      Header: 'Priority',
      Footer: 'Priority',
      accessor: 'priority',
      Filter: DropdownColumnFilter,
      maxWidth: 350,
      minWidth: 150,
      width: 100,
    },

    {
      Header: 'Corrective Action Needed?',
      Footer: 'Corrective Action Needed?',
      accessor: 'corrective_action_needed',
      Filter: DropdownColumnFilter,
      maxWidth: 350,
      minWidth: 150,
      width: 100,
    },
    {
      Header: 'Deadline',
      Footer: 'Deadline',
      accessor: 'deadline',
      Filter: DateColumnFilter,
      canGroupBy: false,
      maxWidth: 500,
      minWidth: 150,
      width: 200,
      Cell: (cell: any) => {
        if (cell.row.isGrouped || cell.value == null) {
          return <span></span>;
        }
        return <span>{dayjs(cell.value).format(dateTimeFormat)}</span>;
      },
    },
    {
      Header: 'Status',
      Footer: 'Status',
      accessor: 'status',
      Filter: DropdownColumnFilter,
      maxWidth: 350,
      minWidth: 150,
      width: 100,
    },
    {
      Header: 'Tags',
      Footer: 'Tags',
      accessor: 'tags',
      Filter: StringColumnFilter,
      canGroupBy: false,
      maxWidth: 350,
      minWidth: 150,
      width: 100,
      Cell: (cell: any) => {
        if (cell.row.isGrouped || cell.value === '') {
          return <span></span>;
        }
        return cell.value.join(', ');
      },
    },
  ];

  useEffect(() => {
    if (openedObservation) {
      const formattedDate = dayjs(new Date(openedObservation.deadline));
      let assignees = [...openedObservation.assignees];
      assignees = assignees.map((a: any) => JSON.parse(a).id);

      form.setFieldsValue({
        ...openedObservation,
        deadline: formattedDate,
        assignees,
      });
      setIsActionNeeded(openedObservation.corrective_action_needed);
    }
  }, [openedObservation]);

  const editObservation = async () => {
    let url = BASE_URL + `/safety_observations/${openedObservation.id}`;

    message.loading({
      content: 'Updating safety observation...',
      duration: 0,
      key: 'loading_msg',
    });

    let assignees: Array<IMetaUser> = [];
    form.getFieldValue('assignees').map((assigneeId: string) => {
      const user = users.find((user: IUser) => user.id === assigneeId);
      assignees.push({
        id: user.id,
        username: user.username,
        email: user.email,
      });
    });
    const vals = {
      ...form.getFieldsValue(),
      corrective_action_needed: isActionNeeded,
      assignees,
    };

    await axios
      .patch(url, vals)
      .then((res) => {
        refetch(); // refetching safety observations
        message.destroy('loading_msg');
        message.success(`Safety Observation updated successfully.`);
      })
      .catch((err) => {
        message.destroy('loading_msg');
        message.error(err.response?.data?.message);
      });
  };

  const showDeleteConfirm = () => {
    confirm({
      open: deleteModalOpen,
      title: 'Are you sure you want to delete this safety observation?',
      icon: <ExclamationCircleFilled />,
      //content: 'Some descriptions',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        setDeleteModalOpen(false);
        deleteObservation();
      },
      onCancel() {
        setDeleteModalOpen(false);
      },
    });
  };

  const deleteObservation = async () => {
    let url = BASE_URL + `/safety_observations/${openedObservation.id}`;

    message.loading({
      content: 'Deleting safety observation...',
      duration: 0,
      key: 'loading_msg',
    });
    await axios
      .delete(url)
      .then((res) => {
        setVisible(false);
        setOpenedObservation(undefined);
        refetch();
        message.destroy('loading_msg');
        message.success(`Safety Observation deleted successfully.`);
      })
      .catch((err) => {
        message.destroy('loading_msg');
        message.error(err.response?.data?.message);
      });
  };

  useEffect(() => {
    console.log(openedObservation, 'selected observation');
  }, [openedObservation]);

  return (
    <div>
      <Spin spinning={isFetching}>
        {paginationLogs?.data?.length > 0 ? (
          <MaximlTable
            data={paginationLogs?.data}
            columns={Columns}
            loading={false}
            columnFiltering
            columnGrouping
          />
        ) : (
          <Empty />
        )}
        {paginationLogs?.data?.length > 0 && (
          <div className="mt-7 flex justify-center w-full">
            <Pagination
              itemRender={itemRender}
              pageSize={paginationLogs?.meta?.itemsPerPage | pageSize}
              total={paginationLogs?.meta?.totalItems}
              defaultCurrent={paginationLogs?.meta?.currentPage | page}
              showSizeChanger={true}
              pageSizeOptions={[5, 10, 20, 30, 50, 100]}
              onChange={(page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
                //navigate(`?page=${page}&limit=${pageSize}`);
                setSearchParams({
                  page: page.toString(),
                  limit: pageSize.toString(),
                });
              }}
            />
          </div>
        )}
      </Spin>
      {openedObservation && (
        <Drawer
          title={
            openedObservation
              ? '#' +
                openedObservation.code +
                ': ' +
                openedObservation.title +
                ' at ' +
                dayjs(openedObservation.time_of_observation).format(
                  dateTimeFormat
                )
              : ''
          }
          placement={'right'}
          onClose={() => {
            setVisible(false);
            setOpenedObservation(undefined);
          }}
          visible={visible}
          width="50%"
          extra={
            // <Dropdown overlay={menu}>
            //   <EllipsisOutlined style={{ fontSize: '24px' }} />
            // </Dropdown>
            <div className="flex items-center mr-4">
              <Button
                type="primary"
                className="mr-2"
                onClick={async () => {
                  setVisible(false);
                  await editObservation();
                  setOpenedObservation(undefined);
                }}
              >
                Save
              </Button>
              <Button danger onClick={showDeleteConfirm}>
                Delete
              </Button>
            </div>
          }
        >
          <Form
            form={form}
            layout="horizontal"
            //labelCol={{ span: 6 }}
            onFinish={async (data: any) => {}}
          >
            <div className="flex flex-row items-center w-[100%]">
              <Form.Item label="Type" name="observation_type">
                <Input
                  placeholder="Type"
                  style={{ width: 180, marginLeft: '2px' }}
                  className="pointer-events-none"
                />
              </Form.Item>
              <Form.Item label="Area" name="area" style={{ marginLeft: '2%' }}>
                <Input
                  placeholder="Area"
                  style={{ width: 100, marginLeft: '2px' }}
                  className="pointer-events-none"
                />
              </Form.Item>
              <Form.Item
                label="Camera"
                name="camera_code"
                style={{ marginLeft: '2%' }}
              >
                <Input
                  placeholder="Camera"
                  style={{ width: 100, marginLeft: '2px' }}
                  className="pointer-events-none"
                />
              </Form.Item>
            </div>

            <div className="flex flex-row items-center">
              <Form.Item label="ROI" name="roi_code">
                <Input
                  placeholder="ROI"
                  style={{ width: 100, marginLeft: '2px' }}
                  className="pointer-events-none"
                />
              </Form.Item>
              <Form.Item
                label="Priority"
                name="priority"
                style={{ marginLeft: '2%' }}
              >
                <Select
                  placeholder="Select Priority"
                  style={{ width: 120 }}
                  options={priorityOptions}
                />
              </Form.Item>
              <Form.Item
                label="Status"
                name="status"
                style={{ marginLeft: '2%' }}
              >
                <Select
                  placeholder="Select Status"
                  style={{ width: 120 }}
                  options={statusOptions}
                />
              </Form.Item>
            </div>

            <Form.Item label="Tags" name="tags">
              <Select
                placeholder="Select Tags"
                mode="tags"
                style={{ width: 200 }}
                options={tagOptions}
              />
            </Form.Item>
            <div className="mb-6">
              {/* <Image src={openedObservation?.evidence} /> */}
              {/* <VideoPlayer videoUrl={openedObservation?.evidence} /> */}
              <video
                src={openedObservation?.evidence}
                width="700"
                height="400"
                autoPlay={true}
                controls
              />
            </div>
            <Form.Item label="Description" name="description">
              <TextArea placeholder="Description.." rows={2} />
            </Form.Item>
            <div className="flex items-center mb-4">
              <span className="text-[14px] mr-4">
                Corrective Action Needed?{' '}
              </span>
              <Checkbox
                checked={isActionNeeded === 'Yes' ? true : false}
                onChange={(e) =>
                  setIsActionNeeded(e.target.checked ? 'Yes' : 'No')
                }
              />
            </div>
            {isActionNeeded === 'Yes' && (
              <>
                <Form.Item
                  label="Describe the corrective action"
                  name="corrective_action_description"
                >
                  <TextArea
                    placeholder="Describe the corrective action.."
                    rows={2}
                  />
                </Form.Item>
                <div className="flex flex-row gap-5">
                  <Form.Item
                    label="Deadline"
                    name="deadline"
                    className="w-[200px]"
                    rules={[{ required: false, message: '' }]}
                  >
                    <DatePicker className="w-full" />
                  </Form.Item>
                  <Form.Item label="Assignee" name="assignees">
                    <Select
                      placeholder="Select Assignee"
                      mode="multiple"
                      style={{ width: 250 }}
                      options={assigneeOptions}
                    />
                  </Form.Item>
                </div>
              </>
            )}

            {/* <Form.Item>
              <Button type="primary" htmlType="submit" className="mt-6">
                Save
              </Button>
            </Form.Item> */}
          </Form>
        </Drawer>
      )}
    </div>
  );
};

export default SafetyObservation;
