import { combineReducers } from '@reduxjs/toolkit';
import apiReducer from './apiReducer/apiReducer';
import authReducer from './authReducer/authReducer';
import { paginationApi } from './apiReducer/paginationSlice/paginationSlice';

const reducers = combineReducers({
  [paginationApi.reducerPath]: paginationApi.reducer,
  api: apiReducer,
  auth: authReducer,
});

export default reducers;
