import { Form, Input, Modal, Select } from 'antd';
import {
  DashboardCamera,
  ICameraDashboard,
} from '../../interfaces/cameradashboard.interface';
import { ICamera } from '../../interfaces/camera.interface';
import axios from 'axios';
import { useEffect, useState } from 'react';

interface IOption {
  label: string;
  value: string;
}
interface CreateDashboardModalProps {
  isEditing: boolean;
  isVisible: boolean;
  setIsVisible: (x: boolean) => void;
  setDashboard: (x: ICameraDashboard | undefined) => void;
  cameras: Array<ICamera>;
}

const CreateDashboardModal = ({
  isEditing,
  isVisible,
  setIsVisible,
  setDashboard,
  cameras,
}: CreateDashboardModalProps) => {
  const [form] = Form.useForm();
  const [cameraOptions, setCameraOptions] = useState<Array<IOption>>([]);

  useEffect(() => {
    const options: Array<IOption> = [];
    cameras.map((camera: ICamera) => {
      options.push({ label: camera.code, value: camera.id });
    });
    setCameraOptions(options);
  }, [cameras]);

  const addCameraDashboard = async (data: ICameraDashboard) => {
    let url = `https://64534b17-3667-4e06-9c87-3b0958c8dd40.mock.pstmn.io/camera_dashboard`;
    //let url = BASE_URL + `/camera_dashboard`;

    await axios
      .post(url, data)
      .then((res) => {
        console.log(res.data, 'post dashboard resp');
        setDashboard(res.data);
      })
      .catch((err) => {
        console.log(err?.message, 'post dashboard err');
      });
  };

  const handleOk = async () => {
    const formVals = await form.validateFields();
    console.log(formVals, 'new dashboard form vals');
    setIsVisible(false);
    //const formVals = form.getFieldsValue();
    const data = {
      ...formVals,
      cameras: [],
    };
    formVals.cameras?.map((val: any, index: number) => {
      const cam: ICamera = cameras.find((c: ICamera) => c.id === val)!;
      data.cameras.push({
        id: cam.id,
        code: cam.code,
        rtsp_url: cam.rtsp_url,
        position: index,
      });
    });

    console.log(data, 'new dashboard data');
    addCameraDashboard(data);
  };

  return (
    <Modal
      title={isEditing ? 'Edit Dashboard' : 'Add Dashboard'}
      open={isVisible}
      onOk={() => {
        handleOk();
      }}
      okText={isEditing ? 'Save' : 'Add'}
      onCancel={() => {
        setIsVisible(false);
        setDashboard(undefined);
      }}
      cancelButtonProps={{ hidden: true }}
      centered
      width={600}
    >
      <Form form={form} layout="vertical" className="mt-6 ml-2">
        <Form.Item
          label="Name"
          name="title"
          rules={[{ required: true, message: 'Please input dashboard title' }]}
        >
          <Input placeholder="Dashboard title" style={{ width: '60%' }} />
        </Form.Item>
        <Form.Item
          label="Cameras"
          name="cameras"
          rules={[
            { required: true, message: 'Please select dashboard cameras' },
          ]}
        >
          <Select
            placeholder="Select Cameras"
            mode="multiple"
            style={{ width: '60%' }}
            options={cameraOptions}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateDashboardModal;
