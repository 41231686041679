import { Avatar, Switch } from 'antd';
import { Cell } from 'react-table';
import { StringColumnFilter } from '../../components/MaximlTable/Filters/StringColumnFilter';
import { DropdownColumnFilter } from '../../components/MaximlTable/Filters/DropdownColumnFilter';
import { ToggleColumnFilter } from '../../components/MaximlTable/Filters/ToggleColumnFilter';
import dayjs from 'dayjs';
import { dateFormat } from '../../settings/settings';

const colors: Array<{ color: string; bgColor: string }> = [
  {
    color: '#f56a00',
    bgColor: '#fde3cf',
  },
  {
    color: '#52c41a',
    bgColor: '#d9f7be',
  },
  {
    color: '#1890ff',
    bgColor: '#bae7ff',
  },
  {
    color: '#722ed1',
    bgColor: '#efdbff',
  },
];

export const Columns: any = {
  User: [
    {
      Header: 'Avatar',
      Footer: 'Avatar',
      accessor: 'avatar',
      disableFilters: true,
      maxWidth: 80,
      minWidth: 50,
      width: 70,
      canGroupBy: false,
      disableSortBy: true,
      Cell: (cell: Cell) => {
        const username: string = cell.row.values.username;
        const randomColor = colors[Math.floor(Math.random() * colors.length)];
        return (
          username !== null && (
            <Avatar
              style={{
                color: randomColor.color,
                backgroundColor: randomColor.bgColor,
              }}
            >
              {username?.substring(0, 1) +
                username?.substring(
                  username?.lastIndexOf(' ') + 1,
                  username?.lastIndexOf(' ') + 2
                )}
            </Avatar>
          )
        );
      },
    },
    {
      Header: 'Full Name',
      Footer: 'Full Name',
      accessor: 'username',
      Filter: StringColumnFilter,
      canGroupBy: false,
      maxWidth: 400,
      minWidth: 200,
      width: 300,
      Cell: (cell: Cell) => (
        <span className="flex items-center h-full">
          {cell.row.values.username}
        </span>
      ),
    },
    {
      Header: 'Email',
      Footer: 'Email',
      accessor: 'email',
      Filter: StringColumnFilter,
      canGroupBy: false,
      maxWidth: 400,
      minWidth: 200,
      width: 300,
      Cell: (cell: Cell) => (
        <span className="flex items-center h-full">
          {cell.row.values.email}
        </span>
      ),
    },
    {
      Header: 'Role',
      Footer: 'Role',
      accessor: 'role',
      Filter: DropdownColumnFilter,
      maxWidth: 400,
      minWidth: 200,
      width: 300,
      Cell: (cell: Cell) => (
        <span className="flex items-center h-full">{cell.row.values.role}</span>
      ),
    },
    {
      Header: 'Active',
      Footer: 'Active',
      accessor: 'is_active',
      Filter: ToggleColumnFilter,
      maxWidth: 400,
      minWidth: 200,
      width: 200,
      Cell: (cell: any) => {
        return <Switch checked={cell.row.values.is_active}></Switch>;
      },
    },
  ],
  Skills: [
    {
      Header: 'Competency',
      Footer: 'Competency',
      accessor: 'name',
      disableSortBy: true,
      disableFilters: true,
      maxWidth: 500,
      minWidth: 150,
      width: 290,
    },
    {
      Header: 'Valid From',
      Footer: 'Valid From',
      accessor: 'valid_from',
      disableSortBy: true,
      disableFilters: true,
      Cell: (cell: any) => {
        if (cell.value === 'No Expiry') {
          return <span>No Expiry</span>;
        } else if (cell.value !== '') {
          return dayjs(cell.value).format(dateFormat);
        } else return <span></span>;
      },
      maxWidth: 500,
      minWidth: 150,
      width: 180,
    },
    {
      Header: 'Valid To',
      Footer: 'Valid To',
      accessor: 'valid_to',
      disableSortBy: true,
      disableFilters: true,
      Cell: (cell: any) => {
        if (cell.value === 'No Expiry') {
          return <span>No Expiry</span>;
        } else if (cell.value !== '') {
          return dayjs(cell.value).format(dateFormat);
        } else return <span></span>;
      },
      maxWidth: 500,
      minWidth: 150,
      width: 180,
    },
  ],
};
