import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import {
  useTable,
  usePagination,
  useBlockLayout,
  useGlobalFilter,
  useSortBy,
  useFilters,
  useResizeColumns,
  useColumnOrder,
  useGroupBy,
  useExpanded,
  Hooks,
  ColumnInstance,
  useRowSelect,
  HeaderProps,
  Filters,
} from 'react-table';
import './table.scss';
import { GlobalFilter } from './GlobalFilter';
import { ColumnHiding } from './ColumnHiding';
import Loading from './Loading';

// ANT Design Imports
import 'antd/dist/reset.css';
import { Button, Select, InputNumber, Tag, Checkbox, Empty } from 'antd';
import {
  CaretDownFilled,
  CaretRightFilled,
  HolderOutlined,
  PushpinTwoTone,
} from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

interface MaximlTableProps {
  data: Array<any>;
  columns: Array<any>;
  loading: boolean;
  hiddenColumns?: Array<string>;
  sorting?: boolean;
  pagination?: boolean;
  columnHiding?: boolean;
  columnResizing?: boolean;
  columnFiltering?: boolean;
  globalFiltering?: boolean;
  columnGrouping?: boolean;
  rowSelection?: boolean;
  singleRowSelection?: boolean;
  rowReorder?: boolean;
  editableCell?: boolean;
  setSelectedRows?: (selectedRows: Array<any>) => void;
  onRightClick?: (row: any, pageX: number, pageY: number) => void;
  onLeftClick?: (event: any, row: any) => void;
  setRow?: (row: any) => void;
  selectedRows?: Array<any>;
  customButton?: () => JSX.Element;
  placeholderText?: string;
  defaultFilters?: Filters<{}>;
  updateData?: (data: any) => void;
  height?: number;
  defaultPageSize?: number;
  getColumnFilters?: (columnFilters: any) => void;
}

const MaximlTable = ({
  data,
  columns,
  loading,
  hiddenColumns,
  sorting,
  pagination,
  columnHiding,
  columnResizing,
  columnFiltering,
  globalFiltering,
  columnGrouping,
  rowSelection,
  singleRowSelection,
  rowReorder,
  editableCell,
  setSelectedRows,
  selectedRows,
  onRightClick,
  onLeftClick,
  placeholderText,
  customButton,
  defaultFilters = [],
  updateData,
  height = window.innerHeight - 220,
  defaultPageSize = 25,
  getColumnFilters,
}: MaximlTableProps) => {
  const { Option } = Select;

  // Create an editable cell renderer
  const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
  }: any) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);

    const onChange = (e: any) => {
      setValue(e.target.value);
    };

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
      updateMyData(index, id, value);
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return editableCell ? (
      <input
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className="outline-0"
      />
    ) : (
      value
    );
  };

  const defaultColumn = useMemo(
    () => ({
      Cell: EditableCell,
      minWidth: 30,
      width: 100,
      maxWidth: 400,
    }),
    []
  );

  const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, ...rest }: any, ref) => {
      const defaultRef = useRef();
      const resolvedRef: any = ref || defaultRef;

      useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return <Checkbox ref={resolvedRef} {...rest}></Checkbox>;
    }
  );

  const useControlledState = (state: any, { instance }: any) => {
    return useMemo(() => {
      if (state.groupBy.length) {
        return {
          ...state,
          hiddenColumns: [...state.hiddenColumns, ...state.groupBy].filter(
            (d, i, all) => all.indexOf(d) === i
          ),
        };
      }
      return state;
    }, [state]);
  };

  // const tableInstance
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    state,
    allColumns,
    getToggleHideAllColumnsProps,
    resetResizing,
    visibleColumns,
    setColumnOrder,
    setGlobalFilter,
    selectedFlatRows,
    rows,
  } = useTable(
    {
      columns,
      data,
      stateReducer: (newState, action) => {
        if (action.type === 'toggleRowSelected' && singleRowSelection) {
          newState.selectedRowIds = {
            [action.id]: true,
          };
        }

        return newState;
      },
      defaultColumn,
      initialState: {
        hiddenColumns: columnHiding ? hiddenColumns : [],
        filters: defaultFilters,
        pageSize: defaultPageSize,
      },
      autoResetSelectedRows: true,
    },
    useGlobalFilter,
    useFilters,
    useBlockLayout,
    useResizeColumns,
    useColumnOrder,
    useGroupBy,

    // custom plugin to add the expander column
    (hooks: Hooks) => {
      hooks.useControlledState.push(useControlledState);
      hooks.visibleColumns.push(
        (columns: Array<ColumnInstance>, { instance }: any) => {
          if (!instance.state.groupBy.length) {
            return columns;
          }

          return [
            {
              id: 'expander', // Make sure it has an ID
              // Build our expander column
              Header: ({ allColumns, state: { groupBy } }: any) => {
                return groupBy.map((columnId: any) => {
                  const column = allColumns.find((d: any) => d.id === columnId);

                  return (
                    <span {...column.getHeaderProps()}>
                      {column.canGroupBy ? (
                        // If the column can be grouped, let's add a toggle
                        <span {...column.getGroupByToggleProps()}>
                          {column.isGrouped ? (
                            <Tag color="processing">
                              {column.render('Header')}
                            </Tag>
                          ) : (
                            <>
                              {column.render('Header')}
                              <PushpinTwoTone className="groupby-icon ml-2" />
                            </>
                          )}
                        </span>
                      ) : null}
                    </span>
                  );
                });
              },
              Cell: ({ row }: any) => {
                if (row.canExpand) {
                  const groupedCell = row.allCells.find(
                    (d: any) => d.isGrouped
                  );

                  return (
                    <span
                      {...row.getToggleRowExpandedProps({
                        style: {
                          // We can even use the row.depth property
                          // and paddingLeft to indicate the depth
                          // of the row
                          paddingLeft: `${row.depth * 2}rem`,
                        },
                      })}
                      className="flex items-center"
                    >
                      {row.isExpanded ? (
                        <CaretDownFilled className="mr-2" />
                      ) : (
                        <CaretRightFilled className="mr-2" />
                      )}{' '}
                      {groupedCell.render('Cell')} ({row.subRows.length})
                    </span>
                  );
                }

                return null;
              },
              width: 250,
              minWidth: 100,
              maxWidth: 400,
            },

            ...columns,
          ];
        }
      );
      rowSelection &&
        hooks.visibleColumns.push((columns: Array<ColumnInstance>) => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }: any) => {
              return (
                <div className="flex items-center justify-start w-full h-full">
                  <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                </div>
              );
            },
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }: any) => {
              return (
                <div
                  className="flex items-center justify-start w-full h-full"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              );
            },
            width: 60,
            minWidth: 60,
            maxWidth: 60,
          },
          ...columns,
        ]);
    },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    if (getColumnFilters) {
      getColumnFilters(state?.filters || []);
      //console.log(state?.filters, 'table state');
    }
  }, [state]);

  useEffect(() => {
    if (setSelectedRows) {
      let tempRows: any = [];
      selectedFlatRows.map((row: any) => {
        if (tempRows.indexOf(row.original) === -1 && !row.isGrouped) {
          tempRows.push(row.original);
        }
      });

      setSelectedRows!(tempRows);
    }
  }, [selectedFlatRows]);

  useEffect(() => {}, [selectedRows]);

  const { pageIndex, pageSize, globalFilter, selectedRowIds, filters } = state;

  // dnd

  const reorderData = (startIndex: number, endIndex: number) => {
    const newData = [...data];
    const [movedRow] = newData.splice(startIndex, 1);
    newData.splice(endIndex, 0, movedRow);
    updateData!(newData);
  };

  const handleDragEnd = (result: any) => {
    const { source, destination } = result;
    if (!destination) return;
    reorderData(source.index, destination.index);
  };

  return (
    <div>
      <div>
        {(globalFiltering || columnHiding || customButton) && (
          <div className="flex items-center justify-between w-full mb-2">
            {globalFiltering && (
              <GlobalFilter
                filter={globalFilter}
                setFilter={setGlobalFilter}
                placeholder={placeholderText}
              />
            )}

            {columnHiding && (
              <ColumnHiding
                allColumns={allColumns}
                getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
              />
            )}

            {customButton && customButton()}
          </div>
        )}

        {loading === false ? (
          data.length !== 0 ? (
            <div>
              <div
                className="maximl-table-wrapper"
                style={{ maxHeight: height }}
              >
                <table {...getTableProps()} className="maximl-table">
                  <thead className="maximl-table-header">
                    {headerGroups.map((headerGroup: any) => (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        className="maximl-table-row"
                      >
                        {rowReorder && (
                          <th
                            className={`maximl-table-header-cell ${
                              columnFiltering
                                ? 'column-filtering-enabled'
                                : 'column-filtering-disabled'
                            }`}
                            // colSpan={1}
                            // role="columnheader"
                            // style={{
                            //   boxSizing: "border-box",
                            //   display: "inline-block",
                            //   position: "relative",
                            //   width: "46px"
                            // }}
                          >
                            <span className="w-[14px] h-[14px]"></span>
                          </th>
                        )}

                        {headerGroup.headers.map((column: any) => (
                          <th
                            {...column.getHeaderProps()}
                            className={`maximl-table-header-cell ${
                              columnFiltering
                                ? 'column-filtering-enabled'
                                : 'column-filtering-disabled'
                            }`}
                          >
                            <span className="flex justify-between w-full h-full">
                              {sorting ? (
                                <span {...column.getSortByToggleProps()}>
                                  {column.render('Header')}
                                  {column.isSorted
                                    ? column.isSortedDesc
                                      ? ' ↓'
                                      : ' ↑ '
                                    : ''}
                                </span>
                              ) : (
                                <span>{column.render('Header')}</span>
                              )}

                              {column.canGroupBy && columnGrouping ? (
                                <span
                                  className="flex items-start my-1 ml-2"
                                  {...column.getGroupByToggleProps()}
                                >
                                  {column.isGrouped ? (
                                    ''
                                  ) : (
                                    <PushpinTwoTone className="groupby-icon" />
                                  )}
                                </span>
                              ) : null}
                            </span>

                            {columnResizing && (
                              <div
                                {...column.getResizerProps()}
                                className={`resizer ${
                                  column.isResizing ? 'isResizing' : ''
                                }`}
                              />
                            )}

                            {columnFiltering && (
                              <div className="inputColumnFilter mt-1">
                                {column.canFilter
                                  ? column.render('Filter')
                                  : null}
                              </div>
                            )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable
                      droppableId="table-body"
                      isDropDisabled={!rowReorder}
                    >
                      {(provided, snapshot) => (
                        <tbody
                          ref={provided.innerRef}
                          {...getTableBodyProps()}
                          {...provided.droppableProps}
                          className={'bg-gray-200'}
                        >
                          {page.map((row: any) => {
                            prepareRow(row);
                            return (
                              row.values[row.groupByID] !== '' &&
                              row.values[row.groupByID] !== null && (
                                <Draggable
                                  draggableId={row.id}
                                  key={row.id}
                                  index={row.index}
                                  isDragDisabled={!rowReorder}
                                >
                                  {(provided, snapshot) => {
                                    return (
                                      <tr
                                        {...row.getRowProps()}
                                        {...provided.draggableProps}
                                        ref={provided.innerRef}
                                        // isDragging={snapshot.isDragging}
                                        onClick={(event) => {
                                          if (onLeftClick) {
                                            !row.isGrouped &&
                                              onLeftClick(event, row);
                                          }
                                        }}
                                        onContextMenu={(event) => {
                                          if (onRightClick) {
                                            event.preventDefault();
                                            onRightClick!(
                                              row.original,
                                              event.pageX,
                                              event.pageY
                                            );
                                          }
                                        }}
                                        className={`maximl-table-row flex items-center bg-white ${
                                          snapshot.isDragging &&
                                          'border-[0.5px]'
                                        }`}
                                      >
                                        {rowReorder && (
                                          <td
                                            className="maximl-table-data-cell text-left"
                                            {...provided.dragHandleProps}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                            }}
                                          >
                                            <div
                                              className="flex items-center"
                                              style={{
                                                padding: '0.8rem 1rem',
                                              }}
                                            >
                                              <HolderOutlined />
                                            </div>
                                          </td>
                                        )}

                                        {row.cells.map((cell: any) => {
                                          return (
                                            <td
                                              className="maximl-table-data-cell text-left"
                                              {...cell.getCellProps()}
                                            >
                                              {cell.render('Cell')}
                                            </td>
                                          );
                                        })}
                                      </tr>
                                    );
                                  }}
                                </Draggable>
                              )
                            );
                          })}
                          {provided.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  </DragDropContext>
                </table>
              </div>

              {pagination && (
                <div className="flex items-center justify-center mt-4">
                  <div className="flex items-center justify-center mr-2">
                    <Button
                      onClick={() => gotoPage(0)}
                      disabled={!canPreviousPage}
                      className="max-sm:hidden mx-2"
                    >
                      {'<<'}
                    </Button>
                    <Button
                      className="mr-2"
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      Previous
                    </Button>
                    <Button onClick={() => nextPage()} disabled={!canNextPage}>
                      Next
                    </Button>
                    <Button
                      onClick={() => gotoPage(pageCount - 1)}
                      disabled={!canNextPage}
                      className="max-sm:hidden mx-2"
                    >
                      {'>>'}
                    </Button>
                    <Select
                      className="max-sm:hidden"
                      value={pageSize}
                      onChange={setPageSize}
                    >
                      {[10, 25, 50, 100].map((pageSize) => (
                        <Option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </Option>
                      ))}
                    </Select>
                    <span className="max-sm:flex ml-2">
                      Go to Page{' '}
                      <InputNumber
                        min={1}
                        defaultValue={pageIndex + 1}
                        onChange={(value) => gotoPage(value! - 1)}
                      />
                    </span>
                  </div>
                  <div className="flex items-center justify-center">
                    <span>
                      Page{' '}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>
                    </span>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <Empty />
          )
        ) : (
          <Loading />
        )}
      </div>

      {/* <pre>
        {JSON.stringify(
          rows.map((row) => row.values),
          null,
          2
        )}
      </pre> */}

      {/* <div>
        <pre>
          <code>{JSON.stringify(state, null, 2)}</code>
        </pre>
      </div> */}

      {/* <pre>
        <code>
          {JSON.stringify(
            {
              'selectedFlatRows[].original': selectedFlatRows.map(
                (d: any) => d.original
              )
            },
            null,
            2
          )}
        </code>
      </pre> */}
    </div>
  );
};

export default MaximlTable;
