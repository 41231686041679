import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { dateTimeFormat } from '../../settings/settings';
import MaximlTable from '../../components/MaximlTable/Table';
import { ICamera } from '../../interfaces/camera.interface';
import { BASE_URL } from '../../env';
import {
  Button,
  message,
  Image,
  Drawer,
  Form,
  Input,
  Select,
  Dropdown,
  Menu,
  Tabs,
  Modal,
} from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import { IEdgeDevice } from '../../interfaces/edgedevice.interface';
import VideoPlayer from './Components/VideoPlayer';
import ROIs from './Components/ROIs/ROIs';

interface IOption {
  label: string;
  value: string;
}

const Cameras = () => {
  const [form] = Form.useForm();
  const [cameras, setCameras] = useState<Array<ICamera>>([]);
  const [loader, setLoader] = useState(true);
  const [visible, setVisible] = useState(false);
  const [camera, setCamera] = useState<ICamera>({} as ICamera);
  const [viewMode, setViewMode] = useState(false);
  const [deviceOptions, setDeviceOptions] = useState<Array<IOption>>([]);
  const areaOptions = [
    { label: 'CCU', value: 'CCU' },
    { label: 'CDU', value: 'CDU' },
    { label: 'BF', value: 'BF' },
    { label: 'IBF', value: 'IBF' },
  ];
  const [activeTab, setActiveTab] = useState('1');

  const getCameras = async () => {
    let url = BASE_URL + '/cameras';
    message.loading({
      content: 'Fetching Cameras...',
      duration: 0,
      key: 'loading_msg',
    });
    await axios
      .get(url)
      .then((res) => {
        message.destroy('loading_msg');
        setLoader(false);
        setCameras(res.data);
      })
      .catch((err) => {
        message.destroy('loading_msg');
        setLoader(false);
        message.error(err.response.data.message);
      });
  };

  const getDevices = async () => {
    let url = BASE_URL + '/edge_device';
    await axios
      .get(url)
      .then((res) => {
        const tmp: Array<IOption> = [];
        res.data.map((d: IEdgeDevice) =>
          tmp.push({ label: d.code, value: d.id })
        );
        setDeviceOptions(tmp);
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  };

  useEffect(() => {
    getCameras();
    getDevices();
  }, []);

  const Columns = [
    {
      Header: 'Camera Id',
      Footer: 'Camera Id',
      accessor: 'code',
      maxWidth: 500,
      minWidth: 150,
      width: 100,
      Cell: (cell: any) => {
        return (
          <div
            className="text-[14px] font-semibold text-blue-500 underline underline-offset-1 ml-2"
            onClick={async () => {
              setCamera(cell.row.original);
              setViewMode(true);
              setVisible(true);
            }}
          >
            {cell.value}
          </div>
        );
      },
    },
    {
      Header: 'Thumbnail',
      Footer: 'Thumbnail',
      accessor: 'thumbnail',
      maxWidth: 500,
      minWidth: 150,
      width: 100,
      Cell: (cell: any) => {
        const getThumbnailUrl = (url: string) => {
          const thumbnailParams = '/c_thumb,q_70,h_70,w_80/';
          const versionIndex = url.indexOf('/v');
          const modifiedUrl =
            url.slice(0, versionIndex) +
            thumbnailParams +
            url.slice(versionIndex + 1);
          return modifiedUrl;
        };
        if (cell.value) {
          return (
            <Image
              src={getThumbnailUrl(cell.value)}
              preview={{ src: cell.value }}
            />
          );
        }
      },
    },
    {
      Header: 'Device Id',
      Footer: 'Device Id',
      accessor: 'device_code',
      maxWidth: 500,
      minWidth: 150,
      width: 200,
    },
    {
      Header: 'Area',
      Footer: 'Area',
      accessor: 'area',
      maxWidth: 350,
      minWidth: 150,
      width: 100,
    },
    {
      Header: '# of ROIs',
      Footer: '# of ROIs',
      accessor: 'roi_count',
      maxWidth: 350,
      minWidth: 150,
      width: 100,
    },
    {
      Header: 'Features enabled',
      Footer: 'Features enabled',
      accessor: 'features_enabled',
      maxWidth: 350,
      minWidth: 150,
      width: 250,
      Cell: (cell: any) => {
        return (
          <div>
            {cell.value?.map((feature: string) => (
              <p>SO: {feature}</p>
            ))}
          </div>
        );
      },
    },
    {
      Header: 'Last Updated At',
      Footer: 'Last Updated At',
      accessor: 'last_updated_at',
      maxWidth: 500,
      minWidth: 150,
      width: 200,
      Cell: (cell: any) => {
        if (cell.row.isGrouped || cell.value === '') {
          return <span></span>;
        }
        return <span>{dayjs(cell.value).format(dateTimeFormat)}</span>;
      },
    },
  ];

  const customButton = () => {
    return (
      <div className="ml-auto">
        <Button
          type="primary"
          onClick={() => {
            setViewMode(false);
            setCamera({} as ICamera);
            setVisible(true);
          }}
          className="ml-2"
        >
          <div className="flex items-center justify-center">
            <PlusOutlined /> <span className="ml-1">Add Camera</span>
          </div>
        </Button>
      </div>
    );
  };

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === '1') {
      setViewMode(false);
    } else {
      setVisible(false);
      deleteCamera();
    }
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={[
        {
          label: 'Edit',
          key: '1',
        },
        {
          label: 'Delete',
          key: '2',
        },
      ]}
    />
  );

  const addCamera = async (data: any) => {
    let url = BASE_URL + `/cameras`;
    message.loading({
      content: 'Adding camera...',
      duration: 0,
      key: 'loading_msg',
    });
    await axios
      .post(url, data)
      .then((res) => {
        setCameras((prevData: any) => [res.data, ...prevData]);
        setCamera(res.data);
        setViewMode(true);
        message.destroy('loading_msg');
        message.success(`Camera added successfully.`);
      })
      .catch((err) => {
        message.destroy('loading_msg');
        message.error(err.response.data.message);
      });
  };

  const editCamera = async (data: any) => {
    let url = BASE_URL + `/cameras/${camera.id}`;
    message.loading({
      content: 'Updating camera...',
      duration: 0,
      key: 'loading_msg',
    });
    await axios
      .patch(url, data)
      .then((res) => {
        let tmpData = cameras.filter((c: ICamera) => c.id !== camera.id);
        setCameras([res.data, ...tmpData]);
        setCamera(res.data);
        setViewMode(true);
        message.destroy('loading_msg');
        message.success(`Camera updated successfully.`);
      })
      .catch((err) => {
        message.destroy('loading_msg');
        message.error(err.response.data.message);
      });
  };

  const deleteCamera = async () => {
    let url = BASE_URL + `/cameras/${camera.id}`;
    message.loading({
      content: 'Deleting camera...',
      duration: 0,
      key: 'loading_msg',
    });
    await axios
      .delete(url)
      .then((res) => {
        let tmpData = cameras.filter((c: ICamera) => c.id !== res.data.id);
        setCameras(tmpData);
        setCamera({} as ICamera);
        message.destroy('loading_msg');
        message.success('Camera deleted successfully');
      })
      .catch((err) => {
        message.destroy('loading_msg');
        message.error(err.response.data.message);
      });
  };

  useEffect(() => {
    if (Object.keys(camera).length) {
      form.setFieldsValue(camera);
    } else {
      form.resetFields();
    }
  }, [camera]);

  const onClose = () => {
    setVisible(false);
    setActiveTab('1');
  };

  const onChange = (key: string) => {
    setActiveTab(key);
  };

  return (
    <>
      <MaximlTable
        data={cameras}
        columns={Columns}
        loading={loader}
        customButton={customButton}
        pagination
      />
      <Drawer
        title={
          viewMode
            ? `${camera.code}`
            : Object.keys(camera).length === 0
            ? 'Add Camera'
            : 'Edit Camera'
        }
        placement={'right'}
        onClose={onClose}
        visible={visible}
        width="50%"
        extra={
          viewMode && (
            <Dropdown overlay={menu}>
              <EllipsisOutlined style={{ fontSize: '24px' }} />
            </Dropdown>
          )
        }
      >
        {!viewMode ? (
          <Form
            labelCol={{ span: 4 }}
            //wrapperCol={{ span: 14 }}
            form={form}
            layout="horizontal"
            onFinish={async (data: any) => {
              if (Object.keys(camera).length) {
                editCamera(data);
              } else {
                addCamera(data);
              }
            }}
          >
            <Form.Item
              label="Camera ID"
              name="code"
              rules={[{ required: true, message: '' }]}
            >
              <Input placeholder="Camera ID" style={{ width: 200 }} />
            </Form.Item>
            <Form.Item
              label="Area"
              name="area"
              rules={[{ required: true, message: '' }]}
            >
              <Select
                placeholder="Select Area"
                style={{ width: 200 }}
                options={areaOptions}
              />
            </Form.Item>
            <Form.Item
              label="Device ID"
              name="device_id"
              rules={[{ required: true, message: '' }]}
            >
              <Select
                placeholder="Select Device"
                style={{ width: 200 }}
                options={deviceOptions}
              />
            </Form.Item>
            <Form.Item
              label="RTSP URL"
              name="rtsp_url"
              rules={[{ required: true, message: '' }]}
            >
              <Input placeholder="rtsp://.." />
            </Form.Item>
            {/* {Object.keys(camera).length > 0 && (
              <div className="ml-10">
                <VideoPlayer
                  videoUrl={`https://smartlens.maximl.com/stream/${camera.code}/index.m3u8`}
                />
              </div>
            )} */}
            <Form.Item>
              <Button type="primary" htmlType="submit" className="mt-6 ml-10">
                {Object.keys(camera).length === 0 ? 'Add' : 'Save'}
              </Button>
            </Form.Item>
          </Form>
        ) : (
          Object.keys(camera).length && (
            <>
              <div className="items-center">
                <span className="text-[15px] font-bold mr-2">Area: </span>
                <span className="text-[14px] mr-6  border border-solid border-gray-300 px-2 py-1.5 rounded-md">
                  {camera.area}
                </span>
                <span className="text-[15px] font-bold mr-2">Device Id: </span>
                <span className="text-[14px] mr-6 border border-solid border-gray-300 p-1.5 rounded-md">
                  {camera.device_code}
                </span>
              </div>
              <Tabs
                defaultActiveKey="1"
                activeKey={activeTab}
                className="mt-10"
                size={'small'}
                onChange={onChange}
                type="card"
              >
                <Tabs.TabPane tab="Streaming" key="1">
                  <>
                    <div className="text-[15px] font-bold mb-2">RTSP URL</div>
                    <div className="text-[14px] border border-solid border-gray-300 p-1.5 mb-6 w-[95%] rounded-md">
                      {camera.rtsp_url}
                    </div>

                    {/* <VideoPlayer
                      videoUrl={`https://smartlens.maximl.com/stream/${camera.code}/index.m3u8`}
                    /> */}
                  </>
                </Tabs.TabPane>
                <Tabs.TabPane tab="ROIs" key="2">
                  <ROIs cameraDetails={camera} activeTab={activeTab} />
                </Tabs.TabPane>
              </Tabs>
            </>
          )
        )}
      </Drawer>
    </>
  );
};

export default Cameras;
