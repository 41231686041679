import React, { useEffect, useState } from 'react';
import MaximlTable from '../../components/MaximlTable/Table';
import { BASE_URL } from '../../env';
import axios from 'axios';
import { Dropdown, Menu, MenuProps, message } from 'antd';
import { Columns } from './Columns';
import useWindowSize from '../../hooks/useWindowSize';
import { IUser } from '../../interfaces/user.interface';
import Profile from './Profile';

const Users = () => {
  const [width, height] = useWindowSize();
  const [users, setUsers] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(true);
  const [row, setRow] = useState<any>();
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const [pageX, setPageX] = useState<any>();
  const [pageY, setPageY] = useState<any>();
  const [showProfile, setShowProfile] = useState(false);
  const [profile, setProfile] = useState({
    id: '',
    username: '',
    designation: '',
    role: '',
    phone_no: '',
    department: '',
    email: '',
  });

  const getUsersData = async () => {
    let url = BASE_URL + '/users';
    message.loading({
      content: 'Fetching Users...',
      duration: 0,
      key: 'loading_msg',
    });
    await axios
      .get(url)
      .then((res) => {
        setUsers(res.data);
        setLoading(false);
        message.destroy('loading_msg');
      })
      .catch((err) => {
        setLoading(false);
        message.destroy('loading_msg');
        message.error(err.response.data.message);
      });
  };
  useEffect(() => {
    getUsersData();
  }, []);

  const deleteUser = async () => {
    let url = BASE_URL + '/users/' + row.id;
    message.loading({
      content: 'Deleting...',
      duration: 0,
      key: 'loading_msg',
    });
    await axios
      .delete(url)
      .then((res) => {
        const tmpData = users.filter((user: IUser) => user.id !== res.data.id);
        setUsers(tmpData);
        message.destroy('loading_msg');
        message.success(`Deleted successfully.`);
      })
      .catch((err) => {
        message.destroy('loading_msg');
        message.error(err.response.data.message);
      });
  };

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === '1') {
      setMenuVisible(false);
      deleteUser();
    }
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={[
        {
          label: 'Delete',
          key: '1',
        },
      ]}
    />
  );
  const showMenu = (row: any, x: number, y: number) => {
    setRow(row);
    setPageX(x);
    setPageY(y);
    setMenuVisible(true);
  };
  const handleVisibleChange = (flag: boolean) => {
    setMenuVisible(flag);
  };
  return (
    <>
      <MaximlTable
        data={users}
        columns={Columns.User}
        loading={loading}
        placeholderText={'Search for users'}
        hiddenColumns={[]}
        sorting
        //pagination
        columnResizing
        columnFiltering
        columnGrouping
        globalFiltering
        columnHiding
        onLeftClick={(event, row) => {
          if (!row.isGrouped) {
            console.log(row.original);
            setProfile(row.original);
            setShowProfile(true);
          }
        }}
        onRightClick={showMenu}
      />
      <Profile
        visible={showProfile}
        setVisible={setShowProfile}
        data={profile}
        //roles={roles}
        showSign={false}
      />
      <div
        className="absolute z-10"
        style={{ top: `${pageY}px`, left: `${pageX}px` }}
      >
        <Dropdown
          overlay={menu}
          onVisibleChange={handleVisibleChange}
          visible={menuVisible}
        >
          <a onClick={(e) => e.preventDefault()}></a>
        </Dropdown>
      </div>
    </>
  );
};

export default Users;
