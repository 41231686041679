import {
  Button,
  Drawer,
  Dropdown,
  Form,
  Input,
  Menu,
  MenuProps,
  message,
} from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import MaximlTable from '../../components/MaximlTable/Table';
import { PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { dateTimeFormat } from '../../settings/settings';
import { BASE_URL } from '../../env';
import { IEdgeDevice } from '../../interfaces/edgedevice.interface';

const EdgeDevices = () => {
  const [form] = Form.useForm();
  const [devices, setDevices] = useState<Array<IEdgeDevice>>([]);
  const [loader, setLoader] = useState(true);
  const [device, setDevice] = useState<IEdgeDevice>({} as IEdgeDevice);
  const [visible, setVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [pageX, setPageX] = useState<number>();
  const [pageY, setPageY] = useState<number>();
  const [menuVisible, setMenuVisible] = useState<boolean>();
  const getEdgeDevices = async () => {
    let url = BASE_URL + '/edge_device';
    message.loading({
      content: 'Fetching...',
      duration: 0,
      key: 'loading_msg',
    });
    await axios
      .get(url)
      .then((res) => {
        message.destroy('loading_msg');
        setLoader(false);
        setDevices(res.data);
      })
      .catch((err) => {
        message.destroy('loading_msg');
        setLoader(false);
        message.error(err.response.data.message);
      });
  };
  useEffect(() => {
    getEdgeDevices();
  }, []);

  const deleteEdgeDevice = async () => {
    let url = BASE_URL + '/edge_device/' + selectedRow.id;
    message.loading({
      content: 'Deleting device...',
      duration: 0,
      key: 'loading_msg',
    });
    await axios
      .delete(url)
      .then((res) => {
        let tmpData = devices.filter((d: IEdgeDevice) => d.id !== res.data.id);

        setDevices(tmpData);
        message.destroy('loading_msg');
        message.success(`Device deleted successfully.`);
      })
      .catch((err) => {
        message.destroy('loading_msg');
        message.error(err.response.data.message);
      });
  };

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === '1') {
      setMenuVisible(false);
      deleteEdgeDevice();
    }
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={[
        {
          label: 'Delete',
          key: '1',
        },
      ]}
    />
  );

  const showMenu = (row: any, x: number, y: number) => {
    setSelectedRow(row);
    setPageX(x);
    setPageY(y);
    setMenuVisible(true);
  };

  const handleVisibleChange = (flag: boolean) => {
    setMenuVisible(flag);
  };

  const DeviceColumns = [
    {
      Header: 'Device Id',
      Footer: 'Device Id',
      accessor: 'code',
      maxWidth: 500,
      minWidth: 150,
      width: 300,
      Cell: (cell: any) => {
        return (
          <div
            className="text-[14px] font-semibold text-blue-500 underline underline-offset-1"
            onClick={() => {
              setVisible(true);
              setDevice(cell.row.original);
            }}
          >
            {cell.value}
          </div>
        );
      },
    },
    {
      Header: 'Camera Count',
      Footer: 'Camera Count',
      accessor: 'camera_count',
      maxWidth: 350,
      minWidth: 150,
      width: 200,
    },
    {
      Header: 'Last Contacted At',
      Footer: 'Last Contacted At',
      accessor: 'last_updated_at',
      maxWidth: 500,
      minWidth: 150,
      width: 300,
      Cell: (cell: any) => {
        if (cell.row.isGrouped || cell.value === '') {
          return <span></span>;
        }
        return <span>{dayjs(cell.value).format(dateTimeFormat)}</span>;
      },
    },
  ];

  const customButton = () => {
    return (
      <div className="ml-auto">
        <Button
          type="primary"
          onClick={() => {
            setVisible(true);
            setDevice({} as IEdgeDevice);
          }}
          className="ml-2"
        >
          <div className="flex items-center justify-center">
            <PlusOutlined /> <span className="ml-1">Add Device</span>
          </div>
        </Button>
      </div>
    );
  };

  useEffect(() => {
    if (Object.keys(device).length) {
      form.setFieldsValue({ code: device.code });
    } else {
      form.resetFields();
    }
  }, [device]);

  const onClose = () => {
    setVisible(false);
  };

  const updateEdgeDevice = async (data: any) => {
    let url = BASE_URL + `/edge_device/` + device.id;
    message.loading({
      content: 'Updating device...',
      duration: 0,
      key: 'updating_msg',
    });
    await axios
      .patch(url, data)
      .then((res) => {
        let tmpData = devices.filter((d: IEdgeDevice) => d.id !== device.id);
        message.destroy('updating_msg');
        message.success(`Device updated successfully.`);
        setDevices([res.data, ...tmpData]);
      })
      .catch((err) => {
        message.destroy('updating_msg');
        message.error(err.response.data.message);
      });
  };

  const addEdgeDevice = async (data: any) => {
    let url = BASE_URL + `/edge_device`;
    message.loading({
      content: 'Adding device...',
      duration: 0,
      key: 'loading_msg',
    });
    await axios
      .post(url, data)
      .then((res) => {
        setDevices((prevData: any) => [res.data, ...prevData]);
        message.destroy('loading_msg');
        message.success(`Device added successfully.`);
      })
      .catch((err) => {
        message.destroy('loading_msg');
        message.error(err.response.data.message);
      });
  };

  return (
    <>
      <MaximlTable
        data={devices}
        columns={DeviceColumns}
        loading={loader}
        customButton={customButton}
        onRightClick={showMenu}
      />
      <Drawer
        title={
          Object.keys(device).length === 0
            ? 'Add Edge Device'
            : 'Edit Edge Device'
        }
        placement={'right'}
        onClose={onClose}
        visible={visible}
        width="450"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={async (data: any) => {
            setVisible(false);
            if (Object.keys(device).length) {
              updateEdgeDevice(data);
            } else {
              addEdgeDevice(data);
            }
          }}
        >
          <Form.Item
            label="Device ID"
            name="code"
            rules={[{ required: true, message: '' }]}
          >
            <Input placeholder="Device ID" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {Object.keys(device).length === 0 ? 'Add' : 'Save'}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
      <div
        className="absolute z-10"
        style={{ top: `${pageY}px`, left: `${pageX}px` }}
      >
        <Dropdown
          overlay={menu}
          onVisibleChange={handleVisibleChange}
          visible={menuVisible}
        >
          <a onClick={(e) => e.preventDefault()}></a>
        </Dropdown>
      </div>
    </>
  );
};

export default EdgeDevices;
