import {
  Layout,
  Dropdown,
  Avatar,
  Menu,
  MenuProps,
  Typography,
  theme,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { logout } from '../../state/reducers/authReducer/authReducer';
import useMatchedRoute from '../../hooks/useMatchedRoute';
const { Header } = Layout;

interface HeaderProps {}

export default function HeaderComponent(props: HeaderProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const route = useMatchedRoute();
  const { Title } = Typography;
  const user = useAppSelector((state) => state.auth.user);
  const onClick: MenuProps['onClick'] = ({ key }) => {
    if (key === '3') {
      dispatch(logout(''));
    }
  };
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const menu = (
    <Menu
      onClick={onClick}
      items={[
        {
          label: (
            <span className="flex flex-col">
              <span className="font-semibold">Admin</span>
              admin@maximl.com
            </span>
          ),
          key: '0',
        },
        {
          type: 'divider',
        },
        {
          label: <span>Log Out</span>,
          key: '3',
        },
      ]}
    />
  );

  return (
    <Header
      style={{ background: colorBgContainer, paddingLeft: '25px' }}
      className="site-layout-background h-14 flex items-center justify-between"
    >
      <div className="flex items-center text-xl">
        <Title level={4}>{route?.name}</Title>
      </div>
      <Dropdown overlay={menu} trigger={['click']}>
        <div
          onClick={(e) => e.preventDefault()}
          className="flex items-center cursor-pointer"
        >
          <Avatar
            className="hover:scale-110 transition ease-in-out delay-100"
            style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
          >
            {user.username?.substring(0, 1)}
          </Avatar>
          <span className="ml-2">{user?.username}</span>
        </div>
      </Dropdown>
    </Header>
  );
}
