import React, { useState } from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

export const DateColumnFilter = ({ column }: any) => {
  const {
    preFilteredRows,
    filterValue = { start_date: '', end_date: '' },
    setFilter,
  } = column;

  const { RangePicker } = DatePicker;
  const dateFormat = 'YYYY-MM-DD';

  const dateChange = (date: any, dateString: any) => {
    let selectedDate = { start_date: '', end_date: '' };
    if (dateString[0] === '' || dateString[1] === '') {
      selectedDate = { start_date: '', end_date: '' };
    } else {
      selectedDate = {
        start_date: dayjs(dateString[0]).format('MM-DD-YYYY'),
        end_date: dayjs(dateString[1]).format('MM-DD-YYYY'),
      };
    }

    setFilter(selectedDate);
  };

  return (
    <div>
      <RangePicker
        // value={[
        //   moment(filterValue.start_date, dateFormat),
        //   moment(filterValue.end_date, dateFormat)
        // ]}
        onChange={dateChange}
        format={dateFormat}
      />
    </div>
  );
};
