import { PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import CreateDashboardModal from './CreateDashboardModal';
import Dashboard from './Dashboard';
import { BASE_URL } from '../../env';
import axios from 'axios';
import {
  DashboardCamera,
  ICameraDashboard,
} from '../../interfaces/cameradashboard.interface';
import { ICamera } from '../../interfaces/camera.interface';

interface tabItem {
  label: string;
  children: any;
  key: string;
  closable: boolean;
}

const LiveFeed = () => {
  const [activeTab, setActiveTab] = useState('1');
  const [dashboards, setDashboards] = useState<Array<ICameraDashboard>>([]);
  const [items, setItems] = useState<Array<tabItem>>([]);
  const [cameras, setCameras] = useState<Array<ICamera>>([]);
  const [dashboard, setDashboard] = useState<ICameraDashboard | undefined>();
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const getCameraDashboards = async () => {
    let url = `https://64534b17-3667-4e06-9c87-3b0958c8dd40.mock.pstmn.io/camera_dashboard`;
    //let url = BASE_URL + `/camera_dashboard`;
    await axios
      .get(url)
      .then((res) => {
        console.log(res.data, 'get dashboards resp');
        setDashboards(res.data);
      })
      .catch((err) => {
        console.log(err?.message, 'get dashboards err');
      });
  };

  const getCameras = async () => {
    let url = BASE_URL + '/cameras';

    await axios
      .get(url)
      .then((res) => {
        setCameras(res.data);
      })
      .catch((err) => {
        console.log(err?.message, 'get cameras err');
      });
  };

  useEffect(() => {
    getCameraDashboards();
    getCameras();
  }, []);

  useEffect(() => {
    const tmpItems: Array<tabItem> = [];
    dashboards.map((dashboard: any, index: number) => {
      tmpItems.push({
        label: dashboard.title,
        children: <></>,
        key: (index + 1).toString(),
        closable: false,
      });
    });
    setItems(tmpItems);
  }, [dashboards]);

  const onTabChange = (activeKey: string) => {
    setActiveTab(activeKey);
  };

  const add = () => {
    const newActiveKey = `${items.length}+1`;
    const newPanes = [...items];
    newPanes.push({
      label: dashboard!.title,
      children: <></>,
      key: newActiveKey,
      closable: false,
    });
    setItems(newPanes);
    setActiveTab(newActiveKey);
  };

  const onEdit = (
    targetKey: React.MouseEvent | React.KeyboardEvent | string,
    action: 'add' | 'remove'
  ) => {
    if (action === 'add') {
      setShowModal(true);
      setIsEditing(false);
    }
  };

  useEffect(() => {
    if (dashboard) {
      add();
    }
  }, [dashboard]);

  return (
    <>
      <Tabs
        type="editable-card"
        onChange={onTabChange}
        activeKey={activeTab}
        onEdit={onEdit}
        items={items}
      />
      <CreateDashboardModal
        isEditing={isEditing}
        isVisible={showModal}
        setIsVisible={setShowModal}
        setDashboard={setDashboard}
        cameras={cameras}
      />
    </>
  );
};

export default LiveFeed;
