import { Route } from '../interfaces/routes.interface';
import Cameras from '../pages/Cameras/Cameras';
import EdgeDevices from '../pages/EdgeDevices/EdgeDevices';
import Home from '../pages/Home/Home';
import LiveFeed from '../pages/LiveFeed/LiveFeed';
import Overview from '../pages/Overview/Overview';
import SafetyObservation from '../pages/SafetyObservation/SafetyObservation';
import Users from '../pages/Users/Users';

export const routes: Array<Route> = [
  { key: 1, name: 'Home', path: '/', element: Overview },
  { key: 2, name: 'Home', path: '/home', element: Overview },
  { key: 3, name: 'Live Feed', path: '/live_feed', element: LiveFeed },
  {
    key: 4,
    name: 'Safety Observations',
    path: '/safety_observations',
    element: SafetyObservation,
  },
  {
    key: 5.1,
    parent_key: '/configuration',
    name: 'Edge Devices',
    path: '/edge_devices',
    element: EdgeDevices,
  },
  {
    key: 5.2,
    parent_key: '/configuration',
    name: 'Cameras',
    path: '/cameras',
    element: Cameras,
  },
  {
    key: 5.3,
    parent_key: '/configuration',
    name: 'Users',
    path: '/users',
    element: Users,
  },
];
